<template>
	<div class="main">

		<v-card height="80px">
			<v-dialog v-model="dialogErro.status" persistent>
				<CardDialog :dialog="dialogErro" />
			</v-dialog>


			<!--COMPONENTE ONDE ESTA OS FILTROS-->
			<v-card-title class="title primary--text">
				Resumo de Serviços
				<v-spacer />
				<v-row no-gutters>
					<v-col class="d-none d-md-block">
						<v-select label="Período" filled dense :items="periodos" item-text="data" item-value="data"
							v-model="busca.dtini" hide-details></v-select>
					</v-col>

					<v-bottom-sheet class="d-flex d-md-none" v-model="sheet">
						<v-sheet class="text-center" height="100%">
							<v-row class="pa-3">
								<v-col class="d-none d-md-block pr-1">
									<v-select label="Período" filled dense :items="periodos" item-text="data"
										item-value="data" v-model="busca.dtini" hide-details></v-select>
								</v-col>

								<v-col cols="6">
									<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
								</v-col>
								<v-col cols="6">
									<v-btn text class="mt-n3" color="primary"
										@click="listar(), sheet = !sheet">Filtrar</v-btn>
								</v-col>
							</v-row>
						</v-sheet>
					</v-bottom-sheet>

					<v-col cols="auto" class="ml-2">
						<v-btn :disabled="carregando" class="mt-1" color="primary" elevation="0" fab x-small
							@click="listar()">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-card-title>
		</v-card>

		<v-card class="my-3 primary" dark>
			<v-card-title>
				Empréstimo Pessoal
				<v-icon class="mx-2" color="white"> mdi-cash</v-icon>
				<v-spacer />
			</v-card-title>

			<v-divider />

			<v-card-text>
				<v-simple-table dense light>
					<thead>
						<tr>
							<th class="text-center">Cód. Empresa</th>
							<th class="text-center">Empresa</th>
							<th class="text-center text-truncate">Meta</th>
							<!--<th class="text-center text-truncate">Propostas</th>-->
							<th class="text-center text-truncate">Realizado</th>
							<th class="text-center text-truncate">Falta</th>
							<th class="text-center text-truncate">%</th>
						</tr>
					</thead>
					<tbody v-if="emprestimoPessoal != null">
						<tr v-for="(ep, i) in emprestimoPessoal" :key="i">
							<td class="text-center">{{ ep.idempresa }}</td>
							<td class="text-center">{{ getEmpresaNome(ep.idempresa) }}</td>
							<td class="text-center">{{ ep.valmeta.toFixed(2) | formataDinheiro }}</td>
							<!--<td class="text-center">{{ ep.propostas }}</td>-->
							<td class="text-center">{{ ep.atingido.toFixed(2) | formataDinheiro }}</td>
							<td class="text-center">{{ ep.falta.toFixed(2) | formataDinheiro }}
							</td>
							<td class="text-center"> <v-chip small
									:class="coresVarMeta(((ep.atingido * 100) / ep.valmeta).toFixed(2))">{{
										((ep.atingido * 100) / ep.valmeta).toFixed(2) }}%
								</v-chip></td>
						</tr>
					</tbody>
          <tbody v-else>
          <tr>
            <td class="text-center" colspan="6">Nenhum registro encontrado.</td>
          </tr>
          </tbody>
					<tfoot class="text-capitalize" v-if="emprestimoPessoal != null">
						<tr class="cursor-normal">
							<td class="text-center bold-text">Totais:</td>
							<td class="text-center"></td>
							<td class="text-center bold-text">{{ emprestimoPessoal.map(v =>
								v.valmeta).reduce((a, v) => a + v,0) |
								formataDinheiro }}</td>
							<td class="text-center bold-text">{{ emprestimoPessoal.map(v =>
								v.atingido).reduce((a, v) => a + v,0) |
								formataDinheiro }}</td>
							<td class="text-center bold-text">{{ emprestimoPessoal.map(v =>
								v.falta).reduce((a, v) => a + v,0) | formataDinheiro }}</td>
							<td class="text-center bold-text"> <v-chip small :class="coresVarMeta(((emprestimoPessoal.map(v => v.atingido).reduce((a, v) => a + v,0)) /
								(emprestimoPessoal.map(v => v.valmeta).reduce((a, v) => a + v,0) || 1) *
								100).toFixed(2))">
									{{ ((emprestimoPessoal.map(v => v.atingido).reduce((a, v) => a + v,0)) /
										(emprestimoPessoal.map(v => v.valmeta).reduce((a, v) => a + v,0) || 1) *
										100).toFixed(2) }}% </v-chip>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>
			</v-card-text>
		</v-card>

		<v-card class="my-3 primary" dark>

			<v-card-title>
				Consignado
				<v-icon class="mx-2" color="white"> mdi-credit-card-fast-outline</v-icon>
			</v-card-title>

			<v-divider />

			<v-progress-linear indeterminate absolute :active="carregando" />
			<v-card-text>
				<v-simple-table dense light>
					<thead>
						<tr>
							<th class="text-center">Cód. Empresa</th>
							<th class="text-center">Empresa</th>
							<th class="text-center text-truncate">Meta</th>
							<!--<th class="text-center text-truncate">Propostas</th>-->
							<th class="text-center text-truncate">Realizado</th>
							<th class="text-center text-truncate">Falta</th>
							<th class="text-center text-truncate">%</th>
						</tr>
					</thead>
					<tbody v-if="consignado !=null">
						<tr v-for="(c, i) in consignado" :key="i">
							<td class="text-center">{{ c.idempresa }}</td>
							<td class="text-center">{{ getEmpresaNome(c.idempresa) }}</td>
							<td class="text-center">{{ c.valmeta.toFixed(2) | formataDinheiro }}</td>
							<!--<td class="text-center">{{ c.propostas }}</td>-->
							<td class="text-center">{{ c.atingido.toFixed(2) | formataDinheiro }}</td>
							<td class="text-center">{{ c.falta.toFixed(2) | formataDinheiro }}
							</td>
							<td class="text-center"> <v-chip small
									:class="coresVarMeta(((c.atingido * 100) / c.valmeta).toFixed(2))">{{ ((c.atingido *
										100) / c.valmeta).toFixed(2) }}%
								</v-chip></td>
						</tr>
					</tbody>
          <tbody v-else>
          <tr>
            <td class="text-center" colspan="6">Nenhum registro encontrado.</td>
          </tr>
          </tbody>
					<tfoot class="text-capitalize" v-if="consignado !=null">
						<tr class="cursor-normal">
							<td class="text-center bold-text">Totais:</td>
							<td class="text-center"></td>
							<td class="text-center bold-text">{{ consignado.map(v =>
								v.valmeta).reduce((a, v) => a + v,0) |
								formataDinheiro }}</td>
							<td class="text-center bold-text">{{ consignado.map(v =>
								v.atingido).reduce((a, v) => a + v,0) |
								formataDinheiro }}</td>
							<td class="text-center bold-text">{{ consignado.map(v =>
								v.falta).reduce((a, v) => a + v,0) | formataDinheiro }}</td>
							<td class="text-center bold-text"> <v-chip small :class="coresVarMeta(((consignado.map(v => v.atingido).reduce((a, v) => a + v,0)) /
								(consignado.map(v => v.valmeta).reduce((a, v) => a + v,0) || 1) *
								100).toFixed(2))">
									{{ ((consignado.map(v => v.atingido).reduce((a, v) => a + v,0)) /
										(consignado.map(v => v.valmeta).reduce((a, v) => a + v,0) || 1) *
										100).toFixed(2) }}% </v-chip>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>
			</v-card-text>
		</v-card>

		<v-card class="my-3 primary" dark>
			<v-card-title>
				Crédito Rural
				<v-icon class="mx-2" color="white"> mdi-tractor</v-icon>
			</v-card-title>

			<v-divider />

			<v-progress-linear indeterminate absolute :active="carregando" />
			<v-card-text>
				<v-simple-table dense light>
					<thead>
						<tr>
							<th class="text-center">Cód. Empresa</th>
							<th class="text-center">Empresa</th>
							<th class="text-center text-truncate">Meta</th>
							<!--<th class="text-center text-truncate">Propostas</th>-->
							<th class="text-center text-truncate">Realizado</th>
							<th class="text-center text-truncate">Falta</th>
							<th class="text-center text-truncate">%</th>
						</tr>
					</thead>
					<tbody v-if="creditoRural != null">
						<tr v-for="(cr, i) in creditoRural" :key="i">
							<td class="text-center">{{ cr.idempresa }}</td>
							<td class="text-center">{{ getEmpresaNome(cr.idempresa) }}</td>
							<td class="text-center">{{ cr.valmeta.toFixed(2) | formataDinheiro }}</td>
							<!--<td class="text-center">{{ cr.propostas }}</td>-->
							<td class="text-center">{{ cr.atingido.toFixed(2) | formataDinheiro }}</td>
							<td class="text-center">{{ cr.falta.toFixed(2) | formataDinheiro }}
							</td>
							<td class="text-center"> <v-chip small
									:class="coresVarMeta(((cr.atingido * 100) / cr.valmeta).toFixed(2))">{{
										((cr.atingido * 100) / cr.valmeta).toFixed(2) }}%
								</v-chip></td>
						</tr>
					</tbody>
          <tbody v-else>
          <tr>
            <td class="text-center" colspan="6">Nenhum registro encontrado.</td>
          </tr>
          </tbody>
					<tfoot class="text-capitalize" v-if="creditoRural != null">
						<tr class="cursor-normal">
							<td class="text-center bold-text">Totais:</td>
							<td class="text-center"></td>
							<td class="text-center bold-text">{{ creditoRural.map(v =>
								v.valmeta).reduce((a, v) => a + v,0) |
								formataDinheiro }}</td>
							<td class="text-center bold-text">{{ creditoRural.map(v =>
								v.atingido).reduce((a, v) => a + v,0) |
								formataDinheiro }}</td>
							<td class="text-center bold-text">{{ creditoRural.map(v =>
								v.falta).reduce((a, v) => a + v,0) | formataDinheiro }}</td>
							<td class="text-center bold-text"> <v-chip small :class="coresVarMeta(((creditoRural.map(v => v.atingido).reduce((a, v) => a + v,0)) /
								(creditoRural.map(v => v.valmeta).reduce((a, v) => a + v,0) || 1) *
								100).toFixed(2))">
									{{ ((creditoRural.map(v => v.atingido).reduce((a, v) => a + v,0)) /
										(creditoRural.map(v => v.valmeta).reduce((a, v) => a + v,0) || 1) *
										100).toFixed(2) }}% </v-chip>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>
			</v-card-text>
		</v-card>

		<v-card class="my-3 primary" dark>

			<v-card-title>
				FGTS
				<v-icon class="mx-2" color="white"> mdi-cash-refund</v-icon>
			</v-card-title>

			<v-divider />

			<v-progress-linear indeterminate absolute :active="carregando" />
			<v-card-text>
				<v-simple-table dense light>
					<thead>
						<tr>
							<th class="text-center">Cód. Empresa</th>
							<th class="text-center">Empresa</th>
							<th class="text-center text-truncate">Meta</th>
							<!--<th class="text-center text-truncate">Propostas</th>-->
							<th class="text-center text-truncate">Realizado</th>
							<th class="text-center text-truncate">Falta</th>
							<th class="text-center text-truncate">%</th>
						</tr>
					</thead>
					<tbody v-if="fgts != null">
						<tr v-for="(f, i) in fgts" :key="i">
							<td class="text-center">{{ f.idempresa }}</td>
							<td class="text-center">{{ getEmpresaNome(f.idempresa) }}</td>
							<td class="text-center">{{ f.valmeta.toFixed(2) | formataDinheiro }}</td>
							<!--<td class="text-center">{{ f.propostas }}</td>-->
							<td class="text-center">{{ f.atingido.toFixed(2) | formataDinheiro }}</td>
							<td class="text-center">{{ f.falta.toFixed(2) | formataDinheiro }}
							</td>
							<td class="text-center"> <v-chip small
									:class="coresVarMeta(((f.atingido * 100) / f.valmeta).toFixed(2))">{{ ((f.atingido *
										100) / f.valmeta).toFixed(2) }}%
								</v-chip></td>
						</tr>
					</tbody>
          <tbody v-else>
          <tr>
            <td class="text-center" colspan="6">Nenhum registro encontrado.</td>
          </tr>
          </tbody>
					<tfoot class="text-capitalize" v-if="fgts != null">
						<tr class="cursor-normal">
							<td class="text-center bold-text">Totais:</td>
							<td class="text-center"></td>
							<td class="text-center bold-text">{{ fgts.map(v =>
								v.valmeta).reduce((a, v) => a + v,0) |
								formataDinheiro }}</td>
							<td class="text-center bold-text">{{ fgts.map(v =>
								v.atingido).reduce((a, v) => a + v,0) |
								formataDinheiro }}</td>
							<td class="text-center bold-text">{{ fgts.map(v =>
								v.falta).reduce((a, v) => a + v,0) | formataDinheiro }}</td>
							<td class="text-center bold-text"> <v-chip small :class="coresVarMeta(((fgts.map(v => v.atingido).reduce((a, v) => a + v,0)) /
								(fgts.map(v => v.valmeta).reduce((a, v) => a + v,0) || 1) *
								100).toFixed(2))">
									{{ ((fgts.map(v => v.atingido).reduce((a, v) => a + v,0)) /
										(fgts.map(v => v.valmeta).reduce((a, v) => a + v,0) || 1) *
										100).toFixed(2) }}% </v-chip>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>
			</v-card-text>
		</v-card>

		<v-progress-linear indeterminate absolute :active="carregando" />

	</div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
//import Paginacao from "../Widgets/Paginacao";
import CardDialog from "../Widgets/CardDialog";
//import InputDatePicker from "../Widgets/InputDatePicker";
export default {
	name: "ContratoLista",
	mixins: [mixinFilial],
	components: { /*Paginacao,*/ CardDialog, /* InputDatePicker */ },
	data: () => ({
		snackbar: { status: false, timeout: 1500 },
		dialog: false,
		dialogLoading: false,
		dialogCloud: false,
		dialogEncomenda: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		sheet: false,
		carregando: true,
		carregandoSkeleton: true,
		carregandoTotais: true,
		dados: {},
		totais: {},
		dadosContrato: { existeContrato: false },
		retornoOnline: "",
		arquivos: [],
		status: {},
		periodos: [],
		busca: {
			idstatus: null,
			idsituacao: null,
			//dtini: `${String(new Date().getMonth() + 1).padStart(2, '0')}/${new Date().getFullYear()}`,
			//dtfim: `${String(new Date().getMonth() + 1).padStart(2, '0')}/${new Date().getFullYear()}`,
			dtini: `${String(new Date().getMonth() + 1).padStart(2, '0')}/${new Date().getFullYear()}`,
			dtfim: `${String(new Date().getMonth() + 1).padStart(2, '0')}/${new Date().getFullYear()}`,
			periodo: { data: null },

		},
		pgSelecionada: 0,
		empresasTitulo: [
			{ "filial": "Ivaiporã", "idfilial": 1 },
			{ "filial": "Pitanga", "idfilial": 2 },
			{ "filial": "São João do Ivaí", "idfilial": 3 },
			{ "filial": "Castelo", "idfilial": 4 },
			{ "filial": "Candido", "idfilial": 5 },
			{ "filial": "Apucarana", "idfilial": 7 },
			{ "filial": "Jardim Alegre", "idfilial": 8 },
			{ "filial": "Manoel Ribas", "idfilial": 9 },
			{ "filial": "Faxinal", "idfilial": 10 },
			{ "filial": "Campo Mourão", "idfilial": 11 },
			{ "filial": "Cianorte", "idfilial": 14 },
			{ "filial": "Paranavaí", "idfilial": 15 },
			{ "filial": "Telêmaco", "idfilial": 16 },
			{ "filial": "Roncador", "idfilial": 17 },
			{ "filial": "Guarapuava", "idfilial": 18 },
			{ "filial": "Palmital", "idfilial": 19 },
			{ "filial": "Ibiporã", "idfilial": 20 },
			{ "filial": "Turvo", "idfilial": 21 },
			{ "filial": "São Mateus do Sul", "idfilial": 23 },
			{ "filial": "São João do Triunfo", "idfilial": 24 },
			{ "filial": "Pontal do Paraná", "idfilial": 26 },
			{ "filial": "Mandaguari", "idfilial": 27 },
			{ "filial": "Astorga", "idfilial": 28 },
			{ "filial": "Cambé", "idfilial": 30 },
			{ "filial": "Reserva", "idfilial": 31 },
			{ "filial": "Rolândia", "idfilial": 35 },
			{ "filial": "Maringá", "idfilial": 36 },
			{ "filial": "Laranjeiras", "idfilial": 38 },
			{ "filial": "E-commerce - CD", "idfilial": 39 }
		],
		servicosTitulo: [
			{ "nome": "Empréstimo Pessoal", "idservico": 1 },
			{ "nome": "Consignado", "idservico": 21 },
			{ "nome": "Crédito Rural", "idservico": 22 },
			{ "nome": "FGTS", "idservico": 23 }

		],
		emprestimoPessoal: null,
		consignado: null,
		creditoRural: null,
		fgts: null,
		totalServicos: null,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		coresVarMeta(per) {
			if (per <= 60) {
				return 'red white--text'
			} else if (per >= 61 && per <= 90) {
				return 'yellow accent-2'
			} else if (per >= 91) {
				return 'blue darken-2 white--text'
			}
		},
		listar() {
			return axios
				.post(`${this.backendUrl}servicos/listar/resumo`, {
					dtini: this.busca.dtini || null,
					dtfim: this.busca.dtini || null,
				})
				.then((res) => {
					this.emprestimoPessoal = res.data.emprestimopessoal;
					this.consignado = res.data.consignado;
					this.creditoRural = res.data.creditorural;
					this.fgts = res.data.fgts;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false
				});
		},
		getEmpresaNome(idempresa) {
			const empresa = this.empresasTitulo.find(e => e.idfilial === idempresa);
			return empresa ? empresa.filial : 'Desconhecido';
		},
		// Filtros
		listarPeriodos() {
			return axios
				.post(`${this.backendUrl}servicos/periodo/listar`)
				.then((res) => {
					if (this.periodos.length > 0) {
						this.periodos = res.data;
					} else {
						this.periodos = [...res.data, {data: `${String(new Date().getMonth() + 1).padStart(2, '0')}/${new Date().getFullYear()}`}];
						this.busca.periodo = res.data[0];
					}
				});
		},
		async init() {
			this.busca.idfilial = this.usuario.idfilial;
			this.busca.dtini = `${String(new Date().getMonth() + 1).padStart(2, '0')}/${new Date().getFullYear()}`;
			await this.listar();
			await this.listarPeriodos();
		},

	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.title {
	height: 64px;
}

.bold-text {
	font-weight: bold;
}
</style>