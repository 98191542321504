import { render, staticRenderFns } from "./DashboardServicos.vue?vue&type=template&id=750a63fb&scoped=true"
import script from "./DashboardServicos.vue?vue&type=script&lang=js"
export * from "./DashboardServicos.vue?vue&type=script&lang=js"
import style0 from "./DashboardServicos.vue?vue&type=style&index=0&id=750a63fb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "750a63fb",
  null
  
)

export default component.exports